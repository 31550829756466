import type { FooterSocialListItem } from './types';
import { $t } from '@/localization';

export function useFooter() {
  const { $analytics } = useNuxtApp();
  const { whatsappHandler } = useConsultation();

  const year = new Date().getFullYear();

  const links = [
    {
      label: $t('projects'),
      path: '/projects',
    },
    {
      label: $t('Properties'),
      path: '/properties',
    },
    {
      label: $t('interactive-map'),
      path: '/interactive-map',
    },
    {
      label: $t('investors-hub'),
      path: '/',
      disabled: true,
    },
  ];

  const social: FooterSocialListItem[] = [
    {
      url: 'https://instagram.com/aro_realestate',
      name: 'instagram',
      icon: 'instagram',
    },
    {
      url: 'https://www.facebook.com/AXCAPITAL.AE/?epa=SEARCH_BOX',
      name: 'facebook',
      icon: 'facebook-se',
    },
    {
      url: '#',
      name: 'whatsapp',
      icon: 'whatsapp-se',
      action: (e) => {
        e.preventDefault();
        e.stopPropagation();
        whatsappHandler();
      },
    },
    {
      url: 'https://www.linkedin.com/company/axcapital-uae/',
      name: 'linkedin',
      icon: 'linkedin',
    },
    {
      url: 'https://twitter.com/AXCAPITALUAE',
      name: 'twitter',
      icon: 'twitter',
    },
  ];

  const bottomLinks = [
    {
      label: $t('footer-privacy'),
      path: '/privacy',
    },
    {
      label: $t('footer-terms'),
      path: '/terms',
    },
    {
      label: $t('footer-user-data'),
      path: '/user-data',
    },
  ];

  function onClickSocial(e: Event, social: FooterSocialListItem) {
    if (social.action) {
      social.action(e);
      return;
    }
    $analytics.trackEvent('click_social_link', 'click', { link: social.url, name: social.name });
  }

  return { year, social, links, bottomLinks, onClickSocial };
}
